@tailwind base;

@layer base {
  :root {
    --amber-100: 60, 33%, 97%;
    --amber-300: 70, 28%, 87%;
    --amber-500: 69, 10%, 55%;
    --amber-600: 70, 15%, 30%;
    --amber-900: 75, 14%, 16%;

    --blue-0: 218, 85%, 97%;
    --blue-50: 229, 100%, 98%;
    --blue-500: 216, 100%, 60%;
    --blue-600: 216, 100%, 50%;
    --blue-700: 218, 100%, 46%;
    --pink-0: theme('colors.pink.50');
    --pink-500: theme('colors.pink.500');
    --grey-300: 213, 22%, 84%;
    --line0: #b30000;
    --line1: #4421af;
    --line2: #0d88e6;
    --line3: #5ad45a;
    --line4: #ebdc78;
    --font-size-xs: 0.7rem;
    --font-size-sm: 0.8rem;
    --font-size-md: 0.875rem;
    --font-size-base: 1rem;
    --font-size-xl: 1.25rem;
    --font-size-2xl: 1.563rem;
    --font-size-3xl: 1.953rem;
    --font-size-4xl: 2.441rem;
    --font-size-5xl: 3.052rem;
  }
}
