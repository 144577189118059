@tailwind utilities;

@layer utilities {
  .body-2xl {
    @apply text-2xl leading-[1.5];
  }
  .body-2xl-semibold {
    @apply text-2xl font-semibold leading-[1.5];
  }
  .body-xl-medium {
    @apply text-[1.125rem] font-medium leading-[1.5] !important;
  }
  .body-xl-semibold {
    @apply text-xl font-semibold leading-[1.5] !important;
  }
  .body-lg {
    @apply text-md leading-[1.5] !important;
  }
  .body-lg-medium {
    @apply text-md font-medium leading-[1.5] !important;
  }
  .body-md-medium {
    @apply text-md !important;
  }
  .body {
    @apply text-base;
  }
  .body-medium {
    @apply text-base font-medium leading-[1.5] !important;
  }
  .body-xs {
    @apply text-sm font-normal leading-[1.5];
  }
  .h1 {
    @apply text-[2rem] font-bold leading-[1.37] tracking-tight !important;
  }
  .h2 {
    @apply text-xl font-[600] leading-[1.4];
  }
  .h3 {
    @apply tablet:text-[2rem] laptop:text-[2.5rem] text-[1.5rem] font-light leading-[0.95] tracking-tighter;
  }
  .display-semibold {
    @apply tablet:text-[1.75rem] text-[1.5rem] font-semibold leading-[1.2];
  }
  .display-extrabold {
    @apply tablet:text-[2rem] text-[1.5rem] font-bold;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
